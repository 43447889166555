import { NgModule } from '@angular/core';


import { HasAnyAuthorityDirective } from './has-any-authority.directive';


@NgModule({
  imports: [],
  declarations: [
    
    HasAnyAuthorityDirective,
   
  ],
  exports: [
   
    HasAnyAuthorityDirective,
   
  ],
})
export class SharedModule {}
