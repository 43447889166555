import { Component } from '@angular/core';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { NavController} from '@ionic/angular';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  // season: any;
  farm: any;
  admin: any;
  // grow: any;
  // harvest: any;
  theme : boolean=true;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateService,
    public navController: NavController
  ) {
    this.initializeApp();
    this.sideMenu();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleLightContent();
      this.splashScreen.hide();
      document.body.classList.add('dark');
    });
    this.initTranslate();
  }

  initTranslate() {
    const enLang = 'en';

    // Set the default language for translation strings, and the current language.
    this.translate.setDefaultLang(enLang);

    if (this.translate.getBrowserLang() !== undefined) {
      this.translate.use(this.translate.getBrowserLang());
    } else {
      this.translate.use(enLang); // Set your language here
    }

    // this.translate.get(['BACK_BUTTON_TEXT']).subscribe(values => {
    //   this.config.set('ios', 'backButtonText', values.BACK_BUTTON_TEXT);
    // });
  }
  sideMenu() {
    this.farm =
    [
     {
      title : 'Site',
      url   : '/tabs/entities/site',
      icon  : 'logo-buffer'
     },
      {
        title : 'Farm',
        url   : '/tabs/entities/farm',
        icon  : 'square'
        }, 
        // {
        //   title : 'Activity',
        //   url   : '/tabs/entities/activity',
        //   icon  : 'square'
        //   },

        // {
        //   title : 'Zone',
        //   url   : '/tabs/entities/zone',
        //   icon  : 'file-tray'
        // },
        // {
        //   title : 'Zone Device',
        //   url   : '/tabs/entities/zone-device',
        //   icon  : 'phone-portrait'
        // },
        // {
        //   title : 'Zone Telemetry',
        //   url   : '/tabs/entities/zone-telemetry',
        //   icon  : 'radio'
        // },
      //   {
      //     title : 'Address',
      //     url   : '/tabs/entities/address',
      //     icon  : 'location'
      // },
      
      // {
      //   title : 'Map Tile',
      //   url   : '/tabs/entities/map-tile',
      //   icon  : 'globe'
      // },
      // {
      //   title : 'Geomap Layer',
      //   url   : '/tabs/entities/geomap-layer',
      //   icon  : 'layers'
      // },
      // {
      //   title : 'Farmer',
      //   url   : '/tabs/entities/farmer',
      //   icon  : 'person'
      //   },
      //   {
      //     title : 'Farmer Group',
      //      url   : '/tabs/entities/farmer-group',
      //      icon  : 'people-circle'
      //  },
      {
        title : 'Device',
        url   : '/tabs/entities/device',
        icon  : 'phone-portrait'
      },
      {
        title : 'Calendar',
        url   : '/tabs/entities/calendar',
        icon  : 'calendar-clear'
      },
      // {
      //   title : 'Plant',
      //   url   : '/tabs/entities/plant',
      //   icon  : 'flower'
      //   },
      {
        title : 'Crop',
        url   : '/tabs/entities/crop',
        icon  : 'rose'
    },
   
      // {
      //   title : 'Device Model',
      //   url   : '/tabs/entities/device-model',
      //   icon  : 'play'
      // },
      // {
      //   title : 'Soil Analysis',
      //   url   : '/tabs/entities/soil-analysis',
      //   icon  : 'flask'
      // },
      // {
      //   title : 'Weather Sensor',
      //   url   : '/tabs/entities/weather-sensor',
      //   icon  : 'thunderstorm'
      // },
      // {
      //   title : 'Soil Sensor',
      //   url   : '/tabs/entities/soil-sensor',
      //   icon  : 'hardware-chip'
      // },
      // {
      //   title : 'Irrigation Controller',
      //   url   : '/tabs/entities/irrigation-controller',
      //   icon  : 'water'
      // },
      // {
      //   title : 'Fertigation Controller',
      //   url   : '/tabs/entities/fertigation-controller',
      //   icon  : 'color-fill'
      // },
      // {
      //   title : 'Leaf Sensor',
      //   url   : '/tabs/entities/leaf-sensor',
      //   icon  : "leaf"
      // },
      // {
      //   title : 'Farm Controller',
      //   url   : '/tabs/entities/farm-controller',
      //   icon  : 'cube'
      // },

    // ];
    // this.season =
    // [
        // {
        // title : 'Season',
        // url   : '/tabs/entities/season',
        // icon  : 'rainy'
        // },
      // {
      //   title : 'Calendar',
      //   url   : '/tabs/entities/calendar',
      //   icon  : 'calendar-clear'
      // },
    //   {
    //     title : 'Seed',
    //     url   : '/tabs/entities/seed',
    //     icon  : 'egg'
    //   },
    //   {
    //     title : 'Raw Material',
    //     url   : '/tabs/entities/raw-material',
    //     icon  : 'file-tray-full'
    //   },
    //   {
    //       title : 'Tool',
    //       url   : '/tabs/entities/tool',
    //       icon  : 'hammer'
    //   },
    //   {
    //     title : 'Farm Implement',
    //     url   : '/tabs/entities/farm-implement',
    //     icon  : 'construct'
    // },
    //   {
    //     title : 'Agro Chemical',
    //     url   : '/tabs/entities/agro-chemical',
    //     icon  : 'scale'
    //   },

    // ];
    // this.grow =
    // [
        // {
        // title : 'Plant',
        // url   : '/tabs/entities/plant',
        // icon  : 'flower'
        // },
      // {
      //   title : 'Breeder',
      //   url   : '/tabs/entities/breeder',
      //   icon  : 'person'
      // },
      // {
      //   title : 'Recipe',
      //   url   : '/tabs/entities/recipe',
      //   icon  : 'information-circle'
      // },
      // {
      //     title : 'Crop',
      //     url   : '/tabs/entities/crop',
      //     icon  : 'rose'
      // },
      // {
      //   title : 'Inspection Batch',
      //   url   : '/tabs/entities/inspection-batch',
      //   icon  : 'receipt'
      // },
      // {
      //   title : 'Crop Activity',
      //   url   : '/tabs/entities/crop-activity',
      //   icon  : "bookmarks"
      // },
      // {
      //   title : 'Crop Zone',
      //   url   : '/tabs/entities/crop-zone',
      //   icon  : "file-tray-full"
      // },
   
      // {
      //   title : 'Expense',
      //   url   : '/tabs/entities/expense',
      //   icon  : 'pricetags'
      // },
      // {
      //   title : 'Event',
      //   url   : '/tabs/entities/event',
      //   icon  : "today"
      // },
      // {
      //   title : 'Alert',
      //   url   : '/tabs/entities/alert',
      //   icon  : 'alert'
      // },
      // {
      //   title : 'Reminder',
      //   url   : '/tabs/entities/reminder',
      //   icon  : 'notifications'
      // },
    ];
    this.admin=[
      {
        title : 'User Management',
        url   : '/user-management',
        icon  : 'people'
      },
      {
        title : 'Privilege Management',
        url   : '/roles-permission-management',
        icon  : 'checkmark-done'
      },
      {
        title : 'Upgrade License',
        url   : '/license',
        icon  : 'caret-up'
      },
    ]
    
    // this.harvest =
    // [
    //   {
    //     title : 'Product',
    //     url   : '/tabs/entities/product',
    //     icon  : 'cube'
    //   },
      
    // ];  
  } 
  
    changeTheme($event) {
      // document.body.classList.add('dark');
      // console.log(this.theme)
      if (this.theme){
        
        document.body.classList.remove('dark');
        this.theme=false
      }
      else {
        
        document.body.classList.add('dark');
        this.theme=true
      }
    }

    openactivities(){
      this.navController.navigateForward('tabs/home')
    }

    openanalytics(){
      this.navController.navigateForward('tabs/account')
    }

    openinsights(){
      this.navController.navigateForward('tabs/insights')
    }
      
    };
